import * as React from "react";
import { Layout, PortfolioBlock, Title } from "../components";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => {
  const { allPortfolios } = data;

  return (
    <>
      <title>
        Portofolio | Cémpes Vastgoed - Dé alleskunner voor uw
        vastgoedontwikkeling
      </title>

      <Layout>
        <Title text="Ruime ervaring in verschillende disciplines" />

        <div className="grid sm:grid-cols-3 gap-6 mt-2">
          {allPortfolios.nodes.map((portfolio) => (
            <PortfolioBlock
              key={portfolio.id}
              project={portfolio.projectName}
              city={portfolio.city}
              image={portfolio.image}
              activities={portfolio.activities}
            />
          ))}
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query Portfolio {
    allPortfolios: allDatoCmsPortfolio(
      sort: { order: ASC, fields: meta___publishedAt }
    ) {
      nodes {
        projectName
        id
        city
        activities {
          value
        }
        meta {
          publishedAt
        }
        image {
          alt
          url
        }
      }
    }
  }
`;
